import React from "react";
import { Link } from "gatsby";

export default function TableContent({ items }) {
  return (
    <div className="faq-content-box">
      {items && items?.map((item, index) => (
        <Link
          to={`#${item.uid}`}
          className="d-block faq-content-item"
          key={index}
        >
          {item.data.faq_title[0].text}
        </Link>
      ))}
    </div>
  );
}
