import React, { useState } from "react";
import { isMobile } from "../../../utils";
import { BsPlus } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import { RichText } from "prismic-reactjs";

export default function Accordion({ title, content }) {
  const [active, setActive] = useState(false);
  return (
    <div className="accordin-box" onClick={() => setActive(!active)}>
      <div className="d-flex cursor-pointer align-items-center" role="button">
        <div style={{ marginRight: 10 }}>
          {active ? (
            <BiMinus
              size={isMobile() ? 16 : 22}
              style={{ marginTop: isMobile() ? -5 : 0 }}
            />
          ) : (
            <BsPlus
              size={isMobile() ? 16 : 22}
              style={{ marginTop: isMobile() ? -5 : 0 }}
            />
          )}
        </div>
        <h5 className={active ? "title active-item" : "title"}>{title}</h5>
      </div>
      {active && (
        <div className="accordion-content">
          <RichText render={content} />
        </div>
      )}
    </div>
  );
}
